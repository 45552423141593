<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        &copy; 2022
        <a href="#"><strong>Rdtalk</strong></a> 
        design by <a href="http://viggoz.com" target="_blank"><strong>乐子</strong></a>
      </div>
      <div class="footer-center">
        <a href="https://beian.miit.gov.cn"><strong>冀ICP备2021020791号-1</strong></a> 
       
      </div>
      
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style>

</style>
